<script lang="ts" setup>
import { ref, computed } from 'vue';

const props = defineProps<{
    modelValue: number | string | boolean | undefined | 'Yes' | 'No',
    min: number | undefined,
    max: number | undefined,
    options: string[] | string | undefined
}>();
const val = ref<Date | undefined>(undefined);
const emit = defineEmits(["update", "next"]);
const load = (value: string | number | boolean | undefined) => {  
}
const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' | 'error' => {
    return 'ok'
}
const value = () => {
   if (val.value == undefined) return new Date();
   return val.value;
}
const title = computed(() => {
    return props.options != undefined && props.options.length > 0 ? props.options[0] : ""
})
const msg = computed(() => {
    return props.options != undefined && props.options.length > 1 ? props.options[1].replace(/\n/gmi, "<br />") : ""
})

defineExpose({ validate, load, value });

</script>

<template>
    <div class="message">
        <el-alert type="info" center :title="title" >
            <div  v-html="msg" style="font-size: 1.5em"></div>
        </el-alert>

    </div>
</template>

<style>
    .message{
        height: 100%;
    }

    .el-alert__title.with-description {
        font-size: 1.7em;
    }
</style>