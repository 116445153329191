import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import ElementPlus, { ElNotification } from 'element-plus'
import Vue3Signature from 'vue3-signature'
import 'element-plus/dist/index.css'
import 'vue-scroll-picker/lib/style.css'

import App from './App.vue'
import router from './router'
import axios from 'axios'
import Worker from './worker?worker'

import * as Sentry from "@sentry/vue";
import Vue3Transitions from 'vue3-transitions'
import Camera from "simple-vue-camera";
import deviceApi from './deviceApi'

const pinia = createPinia()
const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://ef062b59e85a3cb68e112e0772ca9447@o4508065373421568.ingest.de.sentry.io/4508066277556304",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === 'xhr') breadcrumb.data = (hint?.xhr as XMLHttpRequest).response;
    return breadcrumb;
  },
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/app\.checkmate\.software/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const worker = new Worker()
deviceApi.worker = worker;

app.use(Vue3Signature)
app.use(pinia)
app.use(router)
app.use(ElementPlus)
app.use(Vue3Transitions)
app.component("camera", Camera)

app.provide("worker", worker);

axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response != undefined) {
      if (error.response.status === 401) {
        localStorage.clear()
        sessionStorage.clear()
        window.location.assign('/')
        Sentry.setUser(null);
      } else if (error.response.status === 403) {
        router.push('/')
      } else if (error.response.status === 400)  {
        if (error.response.data === 'SubscriptionInvalid') {
          ElNotification({
            title: 'Error',
            message: 'Subscription has ended, please contact your administrator.',
            type: 'error',
            duration: 0
            
          });
        } else if (!error.response.request.responseURL.includes('token')) {
          ElNotification({
            title: 'Error',
            message: error.response.data.message,
            type: 'error'
          });
        }
        return Promise.reject(error)
      } else {
        ElNotification({
          title: 'Error',
          message: error.response.data.message,
          type: 'error'
        });
        return Promise.reject(error)
      }
    } else {
      ElNotification({
        title: 'Error',
        message: error.message,
        type: 'error'
      });
    }
  }
)
axios.defaults.headers.common = { Authorization: `bearer ${localStorage.getItem('access_token')}` }

if (localStorage.getItem('user') != null) {
  let user = JSON.parse(localStorage.getItem('user')!);
  Sentry.setUser({ id: user.id, email: user.email });
}


app.mount('#app')
