<script lang="ts" setup>
import ReportIssueModalComponent from '@/components/ReportIssueModalComponent.vue'
import { ref } from 'vue'
import type { ReportIssueModalRef } from '@/interfaces/ReportIssueModalRef'
import { useChecksStore } from '@/stores/checksStore'
import { CheckStep } from '@/enums/CheckStep'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import type { Issue } from '@/interfaces/Issue'
import { isBlankOrEmpty } from '@/utils'

const emit = defineEmits({
  onStepFinished: () => true
})
const reason = ref("")
const reportIssueModalRef = ref<ReportIssueModalRef>()
const isReportIssueModalComponentOpen = ref(false)
const checksStore = useChecksStore()
const checksOfflineService = CheckOfflineService()
const error = ref(false);
const onSaveReport = async () => {
  if (isBlankOrEmpty(reason.value)) {
    error.value = true;
    return;
  }
  const issue: Issue = {
    severity: 'low',
    description: "Check was late due to: " + reason.value,
    checkId: checksStore.currentCheckMetadata!.checkId,
    taskId: null
  }
  await checksOfflineService.addIssue(issue, checksStore.currentCheckMetadata?.guid!);
  
  onIssueReportSent()
}

const onIssueReportSent = async () => {
  checksStore.updateCurrentCheckStateIsReportSent(true)

  await checksOfflineService.setCheckIsReportSent(checksStore.currentCheckMetadata!.checkId, true)

  await checksOfflineService.updateCheckCurrentStep(
    checksStore.currentCheckMetadata!,
    CheckStep.TaskContents
  )

  emit('onStepFinished')
}
</script>

<template>
  <div class="pane-content-container" style="width: 80%; margin: 0 auto;">
    <el-text size="large" style="font-weight: bold; text-align: center; margin-top: 30px"
      >Why was this overdue?
    </el-text>
    <div v-if="error" style="color: red; text-align: center; margin-top: 10px">Please provide a reason</div>
    <div style="text-align: center">
      <el-input
        v-model="reason"
        rows="8"
        style="width:100%; font-size: 1em"
        type="textarea"
      />
    </div>
    <el-button
      class="report-issue-button"
      style="
        margin-top: 20px;
        background: #79b342;
        color: white;
        width: 100%;
        text-align: center;
        font-weight: bold;
        position: fixed;
        bottom: 0;
        left: 0;
        height: 75px;
        font-size: 20px;
      "
      @click="onSaveReport"
      >
      Next
    </el-button>
  </div>
</template>

<style scoped>
.pane-content-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  height: 100%;
}
.report-issue-button {
  width: 100%;
  border-radius: 8px;
  height: 45px;
  font-size: 20px;
  --el-button-bg-color: #289548;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #289548;
  --el-button-hover-text-color: white;
  text-align:center
}
.report-issue-button > span{
  flex-direction: column
}
</style>
