
export let worker:Worker;

function AppOrWorker()  {
    //if ((window as any).ReactNativeWebView != undefined) return new AppApi();
    return new WorkerApi(worker!);
}

interface IAppOrWorker {
    postMessage(message: object): void;
    isApp(): boolean;
    worker: Worker | undefined;
}

class AppApi implements IAppOrWorker {
    public postMessage(message: object) {
        const windowArea = (window as any).ReactNativeWebView;
        if (windowArea) {
            windowArea.postMessage(JSON.stringify(message));
        } else {
            window.postMessage(JSON.stringify(message));
        }
    }
    worker: Worker | undefined = undefined;
    isApp(): boolean {
        return true;
    }
}

class WorkerApi implements IAppOrWorker {
    constructor(w:Worker) {
        this.worker = w;
    }
    worker: Worker | undefined = undefined
    postMessage(message: object) {
        if (this.worker == undefined) return;
        this.worker.postMessage(message);
    }
    isApp(): boolean {
        return false;
    }
}


 const deviceApi = AppOrWorker();
 export default deviceApi;