<script lang="ts" setup>
    import { ref, defineProps, defineEmits, defineExpose } from 'vue';
    import Camera from "simple-vue-camera";
import { ElNotification } from 'element-plus';

    const camera = ref<InstanceType<typeof Camera>>();
    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const height = window.innerHeight - 400;
    const emit = defineEmits(["update", "next"]);
    const image = ref<string | null>(null);
    const wasSkipped = ref(false)
    const multicamera = ref(false);
    const cameraIndex = ref(0);
    const cameraDevices = ref<MediaDeviceInfo[]>([]);
    const skip = () => {
        wasSkipped.value = true;
        emit('update')
    }
    const load = (value: string | number | boolean | undefined) => {
        camera.value?.devices(["videoinput"]).then(x => {
            cameraDevices.value = x;
            if (x.length > 1) {
                multicamera.value = true;
            }
            if (x.length > 0) {
                cameraError(null);
            }
        }) 
        if (value != undefined) {
            image.value = value.toString();
        }
    }
    const changeCamera = () => {
        let idx = cameraIndex.value++;
        if (idx > cameraDevices.value.length - 1) idx = 0;
        camera.value?.changeCamera(cameraDevices.value[idx].deviceId);
    }
    const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' => {
        if (wasSkipped.value) return "ignore";
        if (image.value == null) return "invalid";
        return 'ok';
    }
    const value = () => {
        if (wasSkipped.value) return "Skipped";
        return image.value;
    }

    const cameraError = (e:any) => {
        navigator.permissions.query({ name: "camera" as PermissionName }).then(res => {
            if(res.state == "granted"){
                ElNotification({title: 'Camera Error', message: 'Camera was not found or was busy. Please make sure that the camera is connected and not used by another application.', type: 'error', customClass: 'notification' }); 
            }
            if (res.state == "denied") {
                ElNotification({title: 'Camera Error', message: 'Camera permissions has been denied, you need to allow camera to use this task.', type: 'error', customClass: 'notification' }); 
            }
            if (res.state == "prompt") {
                ElNotification({title: 'Camera Error', message: 'Please accept the camera permissions.', type: 'error', customClass: 'notification' }); 
            }
        });
    }
       
    const snapshot = async () => {
        const blob = await camera.value?.snapshot();
        if (blob != undefined) {
            var reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                var base64data = reader.result; 
                image.value = base64data?.toString()!;
            }
        }
    }

    const retake = () => {
        image.value = null;
    }

    defineExpose({ validate, load, value, skip });
</script>

<template>
    <div :style="{maxHeight: height + 'px'}">
        <Camera v-if="image == null" ref="camera" :resolution="{ width: height, height: height }" autoplay @error="cameraError">
            <button class="center-button" @click="snapshot">
                <i class="fas fa-camera"></i>
            </button>
            <button class="left-button" v-if="multicamera" @click="changeCamera">
                <i class="fas fa-sync-alt"></i>
            </button>
        </Camera>
        <div class="text-centered" v-if="image != null">
            <img :src="image" :style="{maxWidth: '100%', maxHeight: height + 'px'}"  />
            <button class="retake" @click="retake">Retake</button>
        </div>
   </div>
</template>

<style scoped>
    .center-button{
        position: absolute;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        bottom: 20px;
        left: 50%;
        background:transparent;
        margin-left:-40px;
        border: 3px solid #fff;
    }
    .center-button i {
        color: #000;
        font-size: 2.5em;
        background:#fff;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        line-height: 60px;
    }
    .left-button{
        position: absolute;
        color: #000;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        bottom: 35px;
        left: 50%;
        margin-left: 70px;
        border: 3px solid transparent;
    }
    .text-centered{
        text-align: center;
        position:relative;
    }
    .retake{
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 100px;
        margin-left: -50px;
        background: #fff;
        border: 1px solid #000;
        padding: 10px;
        border-radius: 5px;
    }
</style>