<script lang="ts" setup>
import CardComponent from '@/components/CardComponent.vue'
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import { CheckStep } from '@/enums/CheckStep'
import { useChecksStore } from '@/stores/checksStore'
import { computed } from 'vue'
import { watch } from 'fs'
import { storeToRefs } from 'pinia'

const checksStore = useChecksStore()
const { offlineChecksMetadata } = storeToRefs(checksStore)
const props = defineProps<{
  type: string
}>()
const onIssueReportSent = (check: TodaysCheck) => {
  return;
}
const filteredChecks = computed(() => {
  return offlineChecksMetadata.value.filter((offlineMetadata: any) => {
    if (offlineMetadata.check.canBeFinishedMultipleTimes) {
      return offlineMetadata.check.category === props.type && offlineMetadata.currentCheckStep == CheckStep.Initial
    }
    return (offlineMetadata.check.category === props.type && offlineMetadata.currentCheckStep == CheckStep.Initial)
  })
})
</script>

<template>
  <div v-if="filteredChecks.length > 0">
    <h1>{{props.type}}</h1>

    <div style="display: flex; flex-wrap: wrap; width: 100%; flex-direction: row; gap:1%;">
      <div v-for="offlineMetadata in filteredChecks" :key="offlineMetadata.id" class="card-container">
        <CardComponent :checkOfflineMetadata="offlineMetadata" @onIssueReportSent="onIssueReportSent" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-container{
  width: 100%;
}

@media (min-width: 1024px) {
  .card-container {
    width: 49%;
    min-width: 49%;
    max-width: 49%;
  }
}
@media (min-width: 1600px) {
  .card-container {
    width: 32%;
    min-width: 32%;
    max-width: 32%;
  }
}
</style>
