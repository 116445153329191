<script lang="ts" setup>
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import InProgressCardComponent from '@/components/InProgressCardComponent.vue'
import { CheckStep } from '@/enums/CheckStep'
import { useChecksStore } from '@/stores/checksStore'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

const checksStore = useChecksStore()
const { offlineChecksMetadata } = storeToRefs(checksStore)
const onIssueReportSent = (checkGuid: string) => {
  return;
}
const filteredChecks = computed(() => {
  return offlineChecksMetadata.value
    .filter((x) => x.currentCheckStep != CheckStep.Initial && !x.check.isCompleted)
    .sort((a: any, b: any) => (a.due > b.due ? 1 : -1))
});
</script>

<template>
  <div v-if="filteredChecks.length > 0">
    <h1>In progress</h1>

    <div style="display: flex; flex-wrap: wrap; width: 100%; flex-direction: row; gap:1%;">
      <div
        v-for="offlineMetadata in filteredChecks"
        :key="offlineMetadata.guid"
        class="card-container"
      >
        <InProgressCardComponent
          :check-offline-metadata="offlineMetadata"
          @onIssueReportSent="onIssueReportSent"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.today-panel {
  margin-top: 10px;
}
.card-container{
  width: 100%;
}

@media (min-width: 1024px) {
  .card-container {
    width: 49%;
    min-width: 49%;
    max-width: 49%;
  }
}
@media (min-width: 1600px) {
  .card-container {
    width: 32%;
    min-width: 32%;
    max-width:32%;
  }
}
</style>
