<script lang="ts" setup>
import { ref } from "vue";
const windowWidth = ref(window.innerWidth);

window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

</script>

<template>
    <div :style="{width: windowWidth + 'px'}">
        <slot></slot>
    </div>
</template>
