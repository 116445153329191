<script lang="ts" setup>
import { ref } from 'vue';
import databaseSynchronizationService from '@/services/DatabaseSynchronizationService';
import { useChecksStore } from '@/stores/checksStore';

const isSyncing = ref(false);
const checkStore = useChecksStore();

const sync = async () => {
  isSyncing.value = true;
  const checks = await databaseSynchronizationService.synchronize();
  console.log(checks)
  checkStore.setChecks(checks!);
  isSyncing.value = false;
  setTimeout(async() => await sync(), 180000);
};
setTimeout(async () => {
  await sync();
}, 0);

</script>

<template>
  <i class="hidden fas fa-rotate" @click="sync" v-if="!isSyncing"></i>
</template>

<style>
  .hidden {opacity: 0;}
</style>