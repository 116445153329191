import { ChecksService } from '@/services/ChecksService'
import { clearDatabase } from '@/offlineDatabase'
import { OfflineDatabaseService } from '@/services/OfflineDatabaseService'
import type { TodaysCheck } from '@/interfaces/TodaysCheck'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import { CheckStep } from '@/enums/CheckStep'
import { type CheckOfflineMetadata, offlineDatabase } from '@/offlineDatabase'
import  deviceApi from '@/deviceApi'

class DatabaseSynchronizationService {
  lastSync(d: Date | undefined = undefined): Date | undefined {
    if (d != undefined) localStorage.setItem("lastSync", d.toISOString());
    return localStorage.getItem("lastSync") ? new Date(localStorage.getItem("lastSync")!) : undefined;
  }
    
  synchronize = async (): Promise<CheckOfflineMetadata[] | undefined> => {
    if (this.lastSync() == undefined || this.lastSync()!.getDate() < new Date().getDate())
      await clearDatabase();

    const checks = await this.getChecks();

    this.lastSync(new Date());
    return checks;
  }

  getChecks = async (): Promise<CheckOfflineMetadata[] | undefined> => {
    const self = this;
    const checksService = new ChecksService();
    const checkOfflineService = CheckOfflineService();
    const offlineDatabaseService = OfflineDatabaseService();
    const remoteChecks = await checksService.getTodaysChecks()
    const localChecks = await offlineDatabaseService.getAllChecks()    

    for (const check of remoteChecks) {
      await self.setAlert(check, checkOfflineService);
      await self.addOrReplaceLocalCheckIfNotInUse(check, checkOfflineService);
    }

    for (const checkOfflineMetadata of localChecks) {
      await self.removeDeletedChecks(remoteChecks, checkOfflineMetadata);
    }
    return await checkOfflineService.getAllChecks();
  }

  setAlert = async (check: TodaysCheck, checkOfflineService: any) => {
    if (check.due != "" && check.category == "Today" && !checkOfflineService.isOverdue(check) && !check.isCompleted) {
      deviceApi.postMessage({
        type: "alert",
        minutes: 0,
        start: new Date(),
        message: check.title,
        checkId: check.id,
        due: checkOfflineService.getDueAsDate(check)
      })
    }
  }

  addOrReplaceLocalCheckIfNotInUse = async (check: TodaysCheck, checkOfflineService:any) => {
    const offlineCheck = await checkOfflineService.getCheckById(check.id)
    console.log("added Check", check.id)
    if (offlineCheck == undefined) {
      check.issues = [];
      await checkOfflineService.addCheck(check, CheckStep.Initial)
    } if (offlineCheck != undefined && offlineCheck.check.version < check.version  && offlineCheck?.currentCheckStep == CheckStep.Initial) {
      await checkOfflineService.updateCheck(offlineCheck, check)
    }
  }

  removeDeletedChecks = async (remoteChecks: TodaysCheck[], checkOfflineMetadata: CheckOfflineMetadata) => {
    const onlineCheck = remoteChecks.find((c) => c.id == checkOfflineMetadata.check.id)
    if (!onlineCheck && checkOfflineMetadata?.currentCheckStep == CheckStep.Initial) {
      await offlineDatabase.checkOfflineMetadata.delete(checkOfflineMetadata.id!)
    }
  }
}

const databaseSynchronizationService = new DatabaseSynchronizationService()
export default databaseSynchronizationService;