<script lang="ts" setup>
  import { computed, useSlots, ref } from 'vue'
  const modelValue = defineModel({ type: Number, default: 0})
  const slots = useSlots();
  const windowWidth = ref(window.innerWidth);
  window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

  const slotsData = computed(() => slots.default ? slots.default({}) : []);
  const pages = computed(() => slotsData.value[0] != null ? slotsData.value[0].children?.length : 0);
  const x = computed(() => { return -((windowWidth.value * modelValue.value) + 30) + "px"})
  const w = computed(() => { return windowWidth.value * Number(pages.value) + "px" })
</script>

<template>
  <div class="slider" :style="{width: w, overflow:'hidden', transform: 'translateX(' + x + ')' }">
    <slot></slot>
  </div>
</template>
